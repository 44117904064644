<template>
  <v-container fluid>
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumbTabulator.items" />
    <!--EOC-->
    <!---BOC:[header]-->
    <BreadHeader :title="`Delete ${modelName} Forever`"></BreadHeader>
    <!--EOC-->
    <!---BOC:[error]-->
    <AError :api="api" />
    <!--EOC-->
    <!---BOC:[form]-->
    <v-card>
      <v-card-text>
        <v-alert type="error">
          This action is irreversible. The data will be lost forever.
        </v-alert>
        <span
          >Are you sure you want to delete
          <b>{{ `${modelName}` }}</b> permanently?</span
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
        <v-btn :isLoading="api.isLoading" color="error" @click="api.fetch()">
          Delete Forever
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--EOC-->
    <!---BOC:[note]-->
    <ADevNote
      :text="[
        '✗ Validate authority',
        '✗ Validate ownership',
        '✓ Show breadcrumbs',
        '✓ Show title',
        '✗ Run Read API',
        '✗ Show Read API Loading',
        '✗ Show Read API Error',
        '✗ Replace model item name',
        '✓ Show warning',
        '✓ Show Form Button isLoading',
        '✓ Run Action API',
        '✓ Show Action API Loading',
        '✓ Show Action API Error',
        '✗ Show snackbar',
      ]"
    />
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import BreadcrumbTabulator from "@/objects/breadcrumbTabulator";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    services: (state) => state.service.data.services,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[breadcrumbs]
    breadcrumbTabulator: new BreadcrumbTabulator(),
    //EOC
    //BOC:[model]
    model: new Model(),
    modelId: null,
    modelName: null,
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC

    apiDeleteUrl: '',

  }),
  created() {
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    this.modelId = this.$route.params.modelId;
    this.modelName = `${this.model.name.singular} ${this.modelId}`;
    //EOC
    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbTabulator.addForOperationDeleteHard({
      services: this.services,
      model: this.model,
      modelId: this.modelId,
    });
    //EOC

    if(this.model.apiUrls.apiHardDeleteUrl != ''){
      this.apiDeleteUrl = `${this.$service[this.service.key]}${this.model.apiUrls.apiHardDeleteUrl}/${this.modelId}/delete/hard`;
    }else{
      this.apiDeleteUrl = `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${this.model.key}/${this.modelId}/delete/hard`;
    }

    //BOC:[api]
    this.api.setMethod(`POST`);
    this.api.setUrl(this.apiDeleteUrl);
    this.api.setCallbackCompleted(() => {
      this.$router.push({
        name: "PageServiceModelBreadTabulatorBrowse",
        params: {
          serviceKey: this.model.serviceKey,
          modelKey: this.model.key,
        },
        query: { tab: "trash", viewId: 'all',tabIndex: 0 },
      });
      this.$store.dispatch("showMessage", {
                    message: "Deleted forever successfully.",
                    messageType: "success",
                    timeout:2000
                });
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>